<template>
  <main class="ma-4">
    <v-row class="ma-0 mt-10" justify="center">
      <v-col cols="auto">
        <h2 class="mb-6">
          {{ `${translations.hello}  ${userInfo.account.name}!` }}
        </h2>
        <refer-to-svg width="300px"></refer-to-svg>
      </v-col>
    </v-row>

    <v-row class="ma-0 mt-6" justify="center">
      <v-col class="pa-0" style="text-align: center;">
        <h3>{{ translations.appForAdminOnly }}</h3>
        <p>{{ translations.pleaseReferToEmployeeApp }}</p>

        <v-btn @click="redirectToAdmin" color="main" class="white--text">{{ translations.goToEmployeeApp }}</v-btn>
      </v-col>
    </v-row>
  </main>
</template>

<script>
import { mapState } from "vuex";
import { translations } from "@/utils/common";
import ReferToSvg from "../assets/ReferToSvg.vue";
import { onLogout } from "@/vue-apollo";
import conf from "../utils/api/conf";

export default {
  components: {
    ReferToSvg
  },

  methods: {
    //Need to get the url for mobile app
    redirectToAdmin() {
      onLogout();
      this.$router.push({ name: "Login" });
      window.open(conf.value("VUE_APP_REDIRECT_URL"));
    }
  },

  computed: {
    ...mapState({
      userInfo: state => state.auth.userInfo
    }),
    translations: () => translations
  }
};
</script>
